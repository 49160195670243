import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import Input from "../components/common/Input";
import Error from "../components/common/Error";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import useFetchProfile from "../hooks/useFetchProfile";
import toast from "react-hot-toast";
import useUpdateProfile from "../hooks/useUpdateProfile";
import Image from "../../src/assets/images/profile.svg";
import ModalParent from "../components/common/ModalParent";
import axios from "axios";

const Profile = () => {
  const [submittingImage, setSubmittingImage] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [avatarUrls, setAvatarUrls] = useState([]);

  const token = sessionStorage.getItem("token");

  const { pictures, profileData, error, loading, refetchProfile } =
    useFetchProfile(token);
  const { handleSubmit, Toaster, updating } = useUpdateProfile(token);

  // Extracting profile data and setting default values
  const userName = `${profileData?.first_name} ${profileData?.last_name}` || "";
  const email = `${profileData?.email}` || "";
  const phone_no = `${profileData?.phone_no} ` || "";
  const rate = `${profileData?.rate} ` || "";
  const bio = `${profileData?.bio}` || "";
  const profile_picture = `${profileData?.profile_picture}` || "";

  // Validation schema using Yup

  const Schema = Yup.object().shape({
    name: Yup.string().required("First name is required"),
    phone_no: Yup.string()
      .matches(/^\+?[0-9]\d{0,14}$/, "Invalid phone number")
      .required("Phone is required"),
    rate: Yup.string().required("Rate is required"),
    bio: Yup.string().required("Bio is required").min(3),
  });
  // Formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      name: userName,
      phone_no: phone_no,
      email: email,
      bio: bio,
      rate: rate,
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  // Handles image selection for the profile picture
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageData = e.target.result;

      setSelectedImage(imageData);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (uploadSuccess) {
      refetchProfile();
    }
    setUploadSuccess(false);
  }, [uploadSuccess, refetchProfile]);
  // Updates the selectedImage state when profile_picture changes
  useEffect(() => {
    const imageUrl = ` ${
      profile_picture === "null"
        ? Image
        : profile_picture?.replace("/home/novaet/", "https://")
    }`;
    setSelectedImage(imageUrl);
  }, [profile_picture]);

  // Populating form values with fetched profileData
  useEffect(() => {
    if (profileData) {
      formik.setFieldValue("name", userName);
      formik.setFieldValue("email", email);
      formik.setFieldValue(
        "bio",
        bio.trim() === "null" || bio.trim() === "undefined" ? "" : bio
      );
      formik.setFieldValue(
        "rate",
        rate.trim() === "null" || rate.trim() === "undefined" ? "" : rate
      );
      formik.setFieldValue(
        "phone_no",
        phone_no.trim() === "null" || phone_no.trim() === "undefined"
          ? ""
          : phone_no
      );
    }
  }, [profileData]);

  // Updates the avatarUrls state when pictures change
  useEffect(() => {
    const updatedUrls = pictures?.map((picture) =>
      picture.replace("/home/novaet/", "https://")
    );
    setAvatarUrls(updatedUrls);
  }, [pictures]);

  const handleSubmitImages = async (event) => {
    event.preventDefault();
    try {
      setSubmittingImage(true);
      const form = event.target;
      const formData = new FormData();
      const files = form.querySelector('input[name="pictures"]').files;

      if (files.length !== 5) {
        toast.error("You must upload exactly 5 pictures");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        formData.append("pictures", files[i]);
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/update-pictures`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const message = response.data.message;
      toast.success(message);
      setSubmittingImage(false);
      setUploadSuccess(true);
    } catch (error) {
      setSubmittingImage(false);
      const message = error.message;
      toast.error(message);
    }
  };
  useLayoutEffect(() => {
    document.title = "Profile | Nova";
  }, []);
  return (
    <div className="bg-primaryBlack-dark py-36 text-white">
      <Toaster />{" "}
      {loading || updating ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <div className="w-11/12 mx-auto flex flex-col md:flex-row items-start gap-24 font-beVietnamPro ">
          <div className="w-full md:w-1/2">
            <div className="flex flex-col gap-2 items-center">
              <div className="flex items-end gap-2">
                <img
                  src={selectedImage === null ? Image : selectedImage}
                  alt="ProfilePic"
                  className="rounded-full w-24 h-24 md:w-28 md:h-28 lg:w-36 lg:h-36 object-cover"
                />

                <label htmlFor="picture">
                  <FaEdit className="text-lg lg:text-xl cursor-pointer hover:text-accentPurple-dark transition-all duration-300" />
                </label>
                <input
                  type="file"
                  id="picture"
                  name="picture"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>
              <p className="">{userName}</p>
              <div className="flex justify-center pt-1">
                <ModalParent password />
              </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="flex  flex-col gap-6"
            >
              <div className="flex flex-col gap-1 md:gap-3 ">
                <Input
                  type="email"
                  label="Email"
                  placeHolder="Email"
                  require={true}
                  disabled
                  name="email"
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Input
                  type="text"
                  label="Bio"
                  placeHolder="Bio"
                  require={true}
                  name="bio"
                  value={formik.values.bio}
                  error={formik.touched.bio && formik.errors.bio}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Input
                  type="tel"
                  label="Phone"
                  placeHolder="Phone"
                  require={true}
                  name="phone_no"
                  value={formik.values.phone_no}
                  error={formik.touched.phone_no && formik.errors.phone_no}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Input
                  type="text"
                  label="Rate"
                  placeHolder="Rate per hour"
                  require={true}
                  name="rate"
                  value={formik.values.rate}
                  error={formik.touched.rate && formik.errors.rate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Button type="submit" tertiary handleClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </form>
          </div>
          {pictures.length === 0 ? (
            <div className="w-full md:w-1/2 bg-primaryBlack-medium rounded-lg p-6">
              <div className="flex flex-col gap-3">
                <h3 className="font-DM mx-auto flex justify-center text-xl md:text-2xl lg:text-3xl tracking-wide  text-white">
                  Upload Images
                </h3>
                <p className="text-center pb-6 text-sm md:text-base font-roboto text-white/60 font-light">
                  You have the option to upload an additional five pictures for
                  your fans to view.
                </p>
              </div>
              <div>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSubmitImages}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      type="file"
                      multiple
                      id="pictures"
                      name="pictures"
                      required
                      accept="image/*"
                      // onchange={limitImageSelection(event)}
                      className="block w-full text-sm file:mr-4 file:rounded-md cursor-pointer file:border-0 file:bg-accentPurple-dark/80 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-accentPurple-dark focus:outline-none disabled:pointer-events-none disabled:opacity-60"
                    />
                  </div>
                  <Button
                    type="submit"
                    tertiary
                    small
                    handleClick={handleSubmitImages}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          ) : (
            <div className="w-full md:w-1/2">
              <div className="w-full grid grid-cols-5 gap-3 items-center justify-start pb-4">
                {avatarUrls?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item}
                      alt={userName}
                      className="object-cover w-full h-[3rem] md:h-[5rem] rounded"
                    />
                  );
                })}{" "}
              </div>

              <div className=" bg-primaryBlack-medium rounded-lg p-6">
                {submittingImage ? (
                  <div className="py-2">
                    <Loading />
                  </div>
                ) : (
                  <div>
                    <div className="flex flex-col gap-3">
                      <h3 className="font-DM mx-auto flex justify-center text-xl md:text-2xl lg:text-3xl tracking-wide  text-white">
                        Edit Images
                      </h3>
                      <p className="text-center pb-6 text-sm md:text-base font-roboto text-white/60 font-light">
                        You have the option to edit the pictures you have
                        uploaded.
                      </p>
                    </div>
                    <div>
                      <form
                        className="flex flex-col gap-4"
                        onSubmit={handleSubmitImages}
                      >
                        <div className="mx-auto max-w-xs">
                          <input
                            type="file"
                            multiple
                            id="pictures"
                            name="pictures"
                            required
                            accept="image/*"
                            className="block w-full text-sm file:mr-4 file:rounded-md cursor-pointer file:border-0 file:bg-accentPurple-dark/80 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-accentPurple-dark focus:outline-none disabled:pointer-events-none disabled:opacity-60"
                          />
                        </div>
                        <Button
                          type="submit"
                          tertiary
                          small
                          handleClick={handleSubmitImages}
                        >
                          Submit
                        </Button>
                      </form>
                    </div>
                  </div>
                )}{" "}
              </div>
            </div>
          )}{" "}
        </div>
      )}
    </div>
  );
};

export default Profile;
