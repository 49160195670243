import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Input = ({
  type,
  label,
  value,
  placeHolder,
  error,
  onChange,
  require,
  name,
  readOnly,
  disabled,
  onBlur,
}) => {
  const [hide, setHide] = useState(true);

  const getInputType = () => {
    if (type === "password") {
      return hide ? "password" : "text";
    }
    return type;
  };
  return (
    <div className="z-20 flex font-beVietnamPro flex-col items-start gap-2.5  group ">
      {label ? (
        <label
          htmlFor={name}
          className="text-sm lg:text-base font-light text-white"
        >
          {label} {require && <span className="text-red-300">*</span>}
        </label>
      ) : null}
      <div
        className={`text-white flex items-center justify-between w-full rounded-lg focus:outline-none focus:ring-0  px-4 md:px-5 py-3 placeholder:text-xs md:placeholder:text-sm placeholder:font-beVietnamPro autofill:bg-yellow-200 bg-primaryBlack-medium ${
          error
            ? "border-red-400/60 border-[.5px] "
            : "border-[.5px]  border-white/10 hover:border-white/25 transition-all duration-500"
        }`}
      >
        {/* {type === "password" && hide && "text"} */}
        <input
          disabled={disabled}
          type={getInputType()}
          name={name}
          value={value}
          readOnly={readOnly || false}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeHolder}
          className="focus:outline-none focus:ring-0 bg-primaryBlack-medium w-full"
        />
        {type === "password" && (
          <div className="cursor-pointer " onClick={() => setHide(!hide)}>
            {hide ? <FaEye /> : <FaEyeSlash />}
          </div>
        )}
      </div>

      <p
        className={`transition-all duration-300  text-[13px] text-red-400/70 ${
          error ? "translate-y-0 " : "-z-10 opacity-0 -translate-y-1/2"
        }`}
      >
        {error}
      </p>
    </div>
  );
};

export default Input;
