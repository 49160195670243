import React from "react";
import ImageOne from "../../assets/images/imageOne.jpg";
import ImageTwo from "../../assets/images/imageTwo.png";
import ImageThree from "../../assets/images/imageThree.jpg";
import { motion } from "framer-motion";
const Explanation = () => {
  // scroll animation
  const rightCardAnimate = {
    offScreen: {
      x: 100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const leftCardAnimate = {
    offScreen: {
      x: -100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  return (
    <div className="pt-12 bg-primaryBlack-dark font-beVietnamPro ">
      <div className="w-11/12  mx-auto flex flex-col gap-12 md:gap-24">
        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row "
        >
          <motion.div
            variants={leftCardAnimate}
            className="md:w-1/2  flex flex-col gap-1 lg:gap-2"
          >
            <p className="text-sm  md:text-base lg:text-lg text-accentBlue-dark tracking-wide">
              WHAT IS NOVA?
            </p>

            <div className="flex flex-col gap-2 lg:gap-3">
              <p className="font-DM text-white text-base  md:text-base lg:text-xl xl:text-3xl">
                Nova isn't just another fan platform
              </p>
              <p className="text-white font-light text-sm lg:text-base">
                It's a revolutionary tool for Ethiopian celebrities. We bridge
                the gap between you and your fans, fostering deeper connections
                and unlocking new income opportunities.
              </p>
            </div>
          </motion.div>
          <motion.div
            variants={rightCardAnimate}
            className="flex items-center w-9/12 md:w-1/4   justify-start"
          >
            <img src={ImageOne} alt="" className="rounded-lg" />
          </motion.div>
        </motion.div>

        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.5 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row-reverse "
        >
          <motion.div
            variants={rightCardAnimate}
            className="md:w-1/2  flex flex-col gap-2 lg:gap-3"
          >
            <p className="text-sm md:text-base lg:text-lg text-accentBlue-dark tracking-wide uppercase">
              How Does Nova Help You Earn?
            </p>

            <div className="flex flex-col gap-1 lg:gap-2">
              <p className="font-DM text-white text-base lg:text-xl xl:text-3xl">
                Unlock Your Earning Potential
              </p>

              <ul className="flex flex-col gap-1 text-sm lg:text-base text-white">
                <li className="font-light">
                  <span className="font-semibold">
                    Monetize Your Influence:{" "}
                  </span>
                  Set your rates for personalized video messages and shoutouts,
                  creating a brand new revenue stream directly from your fans.
                </li>
                <li className="font-light">
                  <span className="font-semibold">Expand Your Reach: </span>
                  Nova connects you with fans worldwide, amplifying your fanbase
                  and opening doors to lucrative opportunities beyond borders.
                </li>
              </ul>
            </div>
          </motion.div>
          <motion.div
            variants={leftCardAnimate}
            className="flex items-center w-9/12 md:w-1/4   justify-start"
          >
            <img src={ImageTwo} alt="" className="rounded-lg" />
          </motion.div>
        </motion.div>

        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.5 }}
          className="flex flex-col items-start md:items-center justify-between gap-5 md:flex-row "
        >
          <motion.div
            variants={leftCardAnimate}
            className="md:w-1/2  flex flex-col gap-1 lg:gap-4"
          >
            <p className="text-sm md:text-base lg:text-lg text-accentBlue-dark tracking-wide uppercase">
              How Does Nova Help You Connect with Fans?{" "}
            </p>

            <div className="flex flex-col  gap-2 lg:gap-2">
              <p className="font-DM text-white text-base lg:text-xl xl:text-3xl">
                Forge Authentic Connections{" "}
              </p>
              <ul className="flex flex-col gap-2  text-sm lg:text-base text-white">
                <li className="font-light">
                  <span className="font-semibold">
                    Go Beyond Likes and Comments:{" "}
                  </span>
                  Ditch the impersonal interactions. Nova lets you create
                  personalized video messages and shoutouts that make your fans
                  feel truly seen and valued.
                </li>
                <li className="font-light">
                  <span className="font-semibold">
                    Create Unforgettable Moments:{" "}
                  </span>
                  Show your fans you care! Nova allows you to fulfill their
                  requests and create personalized experiences that leave a
                  lasting impression.
                </li>
              </ul>
            </div>
          </motion.div>
          <motion.div
            variants={rightCardAnimate}
            className="flex items-center w-9/12 md:w-1/4  justify-start"
          >
            <img src={ImageThree} alt="" className="rounded-lg" />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Explanation;
