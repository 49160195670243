import { useEffect, useState } from "react";
import axios from "axios";

const useFetchProfile = (token) => {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pictures, setPictures] = useState(null);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/myprofile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      setProfileData(data.talent);
      setPictures(data.pictures);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchProfile();
  }, [token]);

  const refetchProfile = () => {
    fetchProfile();
  };

  return { pictures, profileData, error, loading, refetchProfile };
};

export default useFetchProfile;
