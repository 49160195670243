import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

const Error = ({ error }) => {
  return (
    <div>
      <div className="w-9/12 mx-auto  flex items-start pt-10 justify-center">
        <div className="text-center flex flex-col gap-2 md:gap-4 ">
          <h1 className="text-5xl md:text-7xl lg:text-8xl font-bold text-accentPurple-dark">
            404
          </h1>
          <div className="text-center flex flex-col gap-1 md:gap-2 ">
            <h1 className="text-2xl text-white md:text-4xl lg:text-6xl font-medium ">
              {error}
            </h1>
            <h1 className="text-white/90 text-sm md:text-base lg:text-xl font-medium ">
              Try Again...
            </h1>
          </div>
          <Link to="/" className="pt-2">
            <Button tertiary> Go to Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
