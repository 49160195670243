import React, { useLayoutEffect } from "react";
import Error from "../components/common/Error";
import Loading from "../components/common/Loading";
import HistoryCards from "../components/layout/HistoryCards";
import { FaVideoSlash } from "react-icons/fa6";
import useFetchCompletedRequest from "../hooks/useFetchCompletedRequest";
const History = () => {
  const token = sessionStorage.getItem("token");
  const { completeRequests, error, isLoading } =
    useFetchCompletedRequest(token);
  useLayoutEffect(() => {
    document.title = "History | Nova";
  }, []);

  return (
    <div className="bg-primaryBlack-dark  font-beVietnamPro text-white">
      {error ? (
        <div className="py-32">
          <Error error={error} />
        </div>
      ) : isLoading ? (
        <div className="py-32">
          <Loading />
        </div>
      ) : (
        <div className="">
          <div className=" pt-28 md:pt-32 h-[12rem] md:h-[15rem] bg-gradient-to-b from-accentBlue-dark to-accentPurple-dark">
            <h1 className="font-DM text-white w-11/12 mx-auto text-lg md:text-3xl">
              History
            </h1>
          </div>
          {completeRequests.length === 0 ? (
            <div className="w-11/12 flex flex-col gap-2 text-center py-20 items-center text-xl md:text-2xl justify-center  tracking-wide mx-auto">
              <h1 className="font-DM text-white/90">
                You have not fulfilled any video requests so far!
              </h1>
              <FaVideoSlash className="text-4xl text-white/90" />
            </div>
          ) : (
            <div className="pb-28">
              <div className="w-11/12 mx-auto mt-10 flex flex-col pb-2 md:pb-4">
                <h1 className="font-DM flex text-lg md:text-xl tracking-wide text-white">
                  List of requests you have completed
                </h1>
              </div>
              <div className="w-11/12 mx-auto overflow-x-auto table-scrollbar">
                <table className="rounded table-auto w-[760px] md:w-full bg-gradient-to-br from-[#1a0e30ab] to-[#38216479]">
                  <thead>
                    <tr className="bg-accentPurple-dark/45  text-sm md:text-base text-center text-white">
                      <td className="p-3 w-1/6"> Recipient Name</td>
                      <td className="p-3 w-1/6"> RecipientPic</td>
                      <td className="p-3 w-1/6"> Recipient Type</td>
                      <td className="p-3 w-1/6"> Request Type</td>
                      <td className="p-3 w-1/6"> Rate </td>
                      <td className="p-3 w-1/6"> Payment Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {completeRequests.map((item, index) => (
                      <HistoryCards request={item} key={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default History;
