import React from "react";
import Home from "./pages/Home";
import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";
import VerifyAndReset from "./pages/VerifyAndReset";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import Verify from "./pages/Verify";
import Enroll from "./pages/Enroll";
import Dashboard from "./pages/Dashboard";
import History from "./pages/History";
import Request from "./pages/Request";
import Profile from "./pages/Profile";
const App = () => {
  const Layout = () => {
    return (
      <div className="">
        <Navigation />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/login",
          element: <AuthPage />,
        },

        {
          path: "/verify",
          element: <Verify />,
        },
        {
          path: "/verify-and-reset",
          element: <VerifyAndReset />,
        },
        {
          path: "/enroll",
          element: <Enroll />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/history",
          element: <History />,
        },
        {
          path: "/request",
          element: <Request />,
        },
      ],
    },
  ]);
  return (
    <div className="max-w-[1440px] mx-auto">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
