import React, { useState } from "react";
import Modal from "./Modal";
import ChangePassword from "./ChangePassword";
import Withdraw from "./Withdraw";
const ModalParent = ({ password, withdraw }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(!showModal);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const actionBar = (
    <div>
      <button
        onClick={handleClose}
        className="font-semibold text-white font-beVietnamPro hover:text-red-400 transition-all duration-200"
      >
        x
      </button>
    </div>
  );
  const modal = (
    <Modal onClose={handleClose} actionBar={actionBar}>
      {password || !withdraw? (
        <ChangePassword showModal={setShowModal} />
      ) : (
        <Withdraw showModal={setShowModal} />
      )}
    </Modal>
  );

  return (
    <div>
      { password || !withdraw ? (
        
        <button
          onClick={handleClick}
          className="text-accentPurple-medium hover:text-accentPurple-dark transition-all duration-200"
        >
          Change Password
        </button>
      ) : (
        <button onClick={handleClick} small>
          Withdraw
        </button>
      )}
      {showModal && modal}
    </div>
  );
};

export default ModalParent;
