import React from "react";

const Loading = () => {
  return (
    <div className="py-5  flex items-center justify-center">
      <div className="w-16 h-16 border-[5px] rounded-full border-t-accentPurple-dark animate-spin"></div>
    </div>
  );
};

export default Loading;
