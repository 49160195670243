import React, { useEffect, useState } from "react";
import Image from "../../assets/images/profile.svg";

const HistoryCards = ({ request }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const imageUrl = ` ${
      request.profile_image === null
        ? Image
        : request.profile_image?.replace("/home/novaet/", "https://")
    }`;
    setAvatar(imageUrl);
  }, [request.profile_image]);

  return (
    <tr className="text-center font-light text-white">
      <td className="px-2">
        <p className="px-2 capitalize text-xs md:text-sm">
          {request.recipient_name}
        </p>
      </td>
      <td>
        <img
          src={avatar}
          alt=""
          className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 mx-auto  p-3 rounded-2xl object-cover "
        />
      </td>
      <td className="px-2">
        <p className="capitalize text-xs md:text-sm">
          {request.recipient_type}
        </p>
      </td>
      <td className="px-2">
        <p className="capitalize text-xs md:text-sm">{request.request_type}</p>
      </td>
      <td className="px-2 bg-accentPurple-medium/40">
        <p className="capitalize text-xs md:text-sm">{request.rate} Birr</p>
      </td>
      <td className="px-2">
        <p className="capitalize text-xs md:text-sm text-green-300">
          {request.payment_status}
        </p>
      </td>
    </tr>
  );
};

export default HistoryCards;
