import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import Input from "../components/common/Input";
import Loading from "../components/common/Loading";
import Button from "../components/common/Button";

const Verify = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if email exists
    if (location.state === null) {
      // Email does not exist, navigate to the register page
      navigate("/register");
    }
  }, [navigate, location.state]);

  let email = "";
  if (location.state !== null) {
    email = location.state.email;
  }

  useLayoutEffect(() => {
    document.title = "Verify OTP | Nova";
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    const verify = {
      email: email,
      otp: values.otp,
    };

    try {
      setLoading(true);
      // Geting response from the api
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-talent`,
        verify
      );
      const message =
        response.data.token && "You have been successfully verified";

      const token = response.data.token;
      // Seting sessionstorage with token send from the api
      sessionStorage.setItem("token", token);
      toast.success(message);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      const message = error.message;
      setLoading(false);
      toast.error(message);
    }
    resetForm();
  };
  useEffect(() => {
    // Retrieve token from session storage
    const tokenStored = sessionStorage.getItem("token");

    // Set the default authorization header for Axios
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenStored}`;
  }, []);

  const Schema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
    formik.setFieldValue(name, value);
  };

  return (
    <div className="py-32 bg-primaryBlack-dark font-beVietnamPro">
      <div className="w-10/12 sm:w-8/12 md:w-1/2 lg:w-1/3 mx-auto">
        <div className="flex flex-col gap-3">
          <h1 className="font-DM mx-auto flex justify-center text-2xl md:text-3xl lg:text-4xl tracking-wide text-white">
            Verify
          </h1>
          <p className="text-center pb-6 text-sm md:text-base font-roboto text-white/60 font-light">
            Please enter the OTP you received on your email address.
          </p>
        </div>
        <Toaster />
        {loading ? (
          <Loading />
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col  gap-1 md:gap-3"
          >
            <Input
              type="text"
              label="Verification Code"
              placeHolder="Verfication code"
              require={true}
              name="otp"
              value={formik.values.otp}
              error={formik.touched.otp && formik.errors.otp}
              onBlur={formik.handleBlur}
              onChange={handleInputChange}
            />

            <Button type="submit" tertiary handleClick={handleSubmit}>
              Verify
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Verify;
