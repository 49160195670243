import React from "react";
import Accordion from "./Accordion";
import { motion } from "framer-motion";
const FAQ = () => {
  const items = [
    {
      id: "1",
      label: "1. What is Nova?",
      content:
        "Nova is a unique platform that connects Ethiopian celebrities directly with their fans through personalized video messages and shoutouts. It allows you to create deeper connections, monetize your talent, and reach a wider audience.",
    },
    {
      id: "2",
      label: "2. How much does it cost?",
      content:
        "There are no fees for Nova Stars to join! You set your rates for each personalized video message you create.",
    },
    {
      id: "3",
      label: "3. How do I get paid?",
      content:
        "Nova handles all secure transactions. You'll receive your earnings directly through your chosen payment method.",
    },
    {
      id: "4",
      label: "4. Do I need special equipment?",
      content:
        "No! All you need is a smartphone with a good camera and internet connection to record your videos.",
    },
    {
      id: "5",
      label: "5. Is it safe?",
      content:
        "Nova prioritizes security. Your personal information and communication with fans are always protected.",
    },
    {
      id: "6",
      label: "6. What kind of support does Nova offer?",
      content:
        "Nova provides comprehensive onboarding and ongoing support to ensure a smooth experience. We're here to help you every step of the way!",
    },
  ];

  return (
    <motion.div
      transition={{ staggerChildren: 0.1 }}
      initial={"offScreen"}
      whileInView={"onScreen"}
      viewport={{ once: true, amount: 0.2 }}
      div
      className=" w-11/12 md:w-9/12 font-beVietnamPro  mx-auto py-16 md:py-24"
    >
      <div className="mx-auto items-center text-center  pb-7 md:pb-10 flex flex-col   gap-1 md:gap-3">
        <h3 className="text-sm md:text-base tracking-wide font-bold text-white/95 uppercase">
          FAQ
        </h3>
        <h3 className="font-DM text-xl md:text-3xl  tracking-wide text-accentPurple-dark ">
          Frequently Asked Questions
        </h3>
        <p className="text-sm  text-white/90 md:w-1/2 ">
          Frequently Asked Questions About Nova
        </p>
      </div>
      <Accordion items={items} />
    </motion.div>
  );
};

export default FAQ;
