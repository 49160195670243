import { IoSparklesSharp } from "react-icons/io5";
import { BiSolidVideoRecording } from "react-icons/bi";
import { FaCircleCheck } from "react-icons/fa6";
import { RiQuestionAnswerFill } from "react-icons/ri";

export const talent = [
  {
    icon: <FaCircleCheck />,
    title: "Join Nova",
    desc: " Sign up as a Nova Star and create your profile. You set your ratesand control your schedule.",
  },

  {
    icon: <RiQuestionAnswerFill />,
    title: "Receive Requests",
    desc: "Fans send personalized video requests for birthdays, shoutouts, encouragement, and more.",
  },

  {
    icon: <BiSolidVideoRecording />,
    title: "Craft Your Message",
    desc: "Record a personalized video message for each request, adding your unique touch.",
  },

  {
    icon: <IoSparklesSharp />,
    title: "Deliver the Brilliance",
    desc: "Share your video message with your fans and watch their joy light up your day!",
  },
];
