import React from "react";
import HeroPic from "../../assets/images/maxresdefault.jpg";
import Button from "../common/Button";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Hero = () => {
  //scroll animation
  const leftAnimate = {
    offScreen: { x: -100 },
    onScreen: {
      x: 0,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const rightAnimate = {
    offScreen: { x: 100 },
    onScreen: {
      x: 0,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  return (
    <motion.div
      transition={{ staggerChildren: 0.1 }}
      initial={"offScreen"}
      animate={"onScreen"}
      className="bg-hero-pattern bg-cover"
    >
      <div className="flex flex-col md:flex-row items-start md:items-center gap-14 w-11/12 mx-auto pt-36 md:pt-48 pb-28">
        <motion.div
          variants={leftAnimate}
          className="flex flex-col font-beVietnamPro gap-5 md:gap-6 md:w-1/2 items-start "
        >
          <div className="flex flex-col gap-1 md:gap-2">
            <h1 className="font-DM text-2xl md:text-2xl  md:leading-9 xl:leading-[3.5rem] xl:text-4xl font- text-white">
              Unlock a New Revenue Stream Nova for Ethiopian Celebrities!
            </h1>
            <p className="text-white/90 w-11/12  text-sm md:text-base">
              Start Earning Today! Join Nova and Connect with Your Fans Through
              Personalized Videos.
            </p>
          </div>
          <Link to="/enroll" className="capitalize">
            <Button tertiary> Enroll</Button>
          </Link>
        </motion.div>
        <motion.div
          variants={rightAnimate}
          className="md:w-1/2 w-10/12 flex  md:justify-end"
        >
          <img src={HeroPic} alt="Hero pic" className="rounded-lg " />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Hero;
