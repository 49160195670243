import React from "react";
import { talent } from "../../data";
import { motion } from "framer-motion";
const HowItWorks = () => {
  // scroll amination
  const rightCardAnimate = {
    offScreen: {
      x: 100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  const leftCardAnimate = {
    offScreen: {
      x: -100,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  //scroll animation

  const BottomCardAnimate = {
    offScreen: {
      y: 100,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };

  return (
    <div className="bg-primaryBlack-dark  pt-16 md:pt-28 ">
      <div className="w-11/12 mx-auto">
        <div className="flex items-start md:items-center gap-1 md:gap-2 flex-col">
          <h1 className="font-DM text-xl text-center md:text-left md:text-xl  xl:text-3xl text-white">
            How It Works
          </h1>
          <p className="text-white/80 text-center md:text-left  font-roboto text-sm md:text-base tracking-wide">
            Get a personal video in just four steps.
          </p>
        </div>
        <motion.div
          transition={{ staggerChildren: 0.1 }}
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: true, amount: 0.2 }}
          className="pt-6 md:pt-7 grid gird-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 "
        >
          {talent.map((item, index) => {
            return (
              <motion.div
                variants={
                  index === 0
                    ? leftCardAnimate
                    : index === 1 || index === 2
                    ? BottomCardAnimate
                    : rightCardAnimate
                }
                key={index}
                className="bg-primaryBlack-medium/85 rounded-xl  p-6 md:p-7 transition-all duration-100 hover:bg-primaryBlack-meduim"
              >
                <div className="flex flex-col gap-1.5 md:gap-3">
                  <div className="flex flex-col gap-1 md:gap-2">
                    <div className="text-white/90 text-2xl md:text-3xl">
                      {item.icon}
                    </div>
                    <h1 className="font-DM text-base md:text-lg  lg:text-xl  text-white">
                      {item.title}
                    </h1>
                  </div>
                  <p className="text-white/75 font-beVietnamPro  font-light text-sm">
                    {item.desc}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default HowItWorks;
