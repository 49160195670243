import React, { useState } from "react";

const DropDown = ({ label, id, header, data, onChange, error }) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState(label);

  const handleChange = (name) => {
    setSelected(name);
    onChange(name);
  };

  return (
    <div className="z-20 flex font-beVietnamPro flex-col items-start gap-2.5  group ">
      {header ? (
        <div className="text-sm lg:text-base font-light text-white">
          {header} {require && <span className="text-red-300">*</span>}
        </div>
      ) : null}
      <button
        onClick={() => setShowList((prev) => !prev)}
        onBlur={() => setShowList(false)}
        id={id}
        data-dropdown-toggle="dropdown"
        className="w-full text-white bg-primaryBlack-medium border-[.5px]  border-white/10 hover:border-white/25 rounded-lg px-4 md:px-5 py-3 focus:outline-none text-sm  text-center flex items-start justify-between relative gap-4 capitalize group"
        type="button"
      >
        <span className=" text-sm md:text-base">{selected}</span>

        <svg
          className="w-4 h-4  "
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
        <div
          id="dropdown"
          className={`z-[50] w-full
           absolute top-full left-0 bg-primaryBlack-medium mt-1 rounded shadow p-3  ${
             showList ? " visible opacity-100" : "invisible opacity-0"
           }`}
        >
          <ul
            className=" text-sm text-center  max-h-[14rem] overflow-y-auto"
            aria-labelledby="dropdownDefaultButton"
          >
            {data.map(({ name }, idx) => {
              return (
                <li key={idx} onClick={() => handleChange(name)}>
                  <p className=" capitalize border-b border-b-darkGray py-1.5 text-sm md:text-base relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]">
                    {name}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </button>
      {error && (
        <p
          className={`transition-all duration-300  text-[13px] text-red-400/70 ${
            error ? "translate-y-0 " : "-z-10 opacity-0 -translate-y-1/2"
          }`}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default DropDown;
