import axios from "axios";
import * as Yup from "yup";
import React, { useEffect, useLayoutEffect, useState } from "react";
import RequestCards from "../components/common/RequestCards";
import Error from "../components/common/Error";
import Loading from "../components/common/Loading";
import { FaArrowLeftLong, FaVideo, FaVideoSlash } from "react-icons/fa6";
import Input from "../components/common/Input";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import Button from "../components/common/Button";
import { useNavigate } from "react-router-dom";
import Image from "../assets/images/profile.svg";
const Request = () => {
  const [avatar, setAvatar] = useState(null);
  const token = sessionStorage.getItem("token");
  const [updating, setUpdating] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardMob, setSelectedCardMob] = useState(false);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [messageDetails, setMessageDetails] = useState(null);
  const fetchAllPendingRequests = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/pending-requests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.pendingRequests;
      setPendingRequest(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchAllPendingRequests();
  }, []);

  useEffect(() => {
    if (messageDetails !== null) {
      const imageUrl = ` ${
        messageDetails?.user_profile_image === null
          ? Image
          : messageDetails?.user_profile_image?.replace(
              "/home/novaet/",
              "https://"
            )
      }`;
      setAvatar(imageUrl);
    }
  }, [messageDetails, avatar]);

  const handleRequestClick = (item, index) => {
    setMessageDetails(item);
    setSelectedCardMob(true);
    setSelectedCard(index);
  };

  const handleSubmit = async (values) => {
    // Get the selected file
    const fileInput = document.getElementById("video").files[0];

    var data = new FormData();
    data.append("request_id", messageDetails.request_id);
    data.append("video_title", values.video_title);
    if (fileInput) {
      data.append("video", fileInput);
    }

    try {
      // Getting response from the API
      setUpdating(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/upload-request-video`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const message = response.data.message;
      toast.success(message);
      setTimeout(() => {
        navigate("/");
      }, 2000);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      const message = error.message;
      toast.error(message);
    }
  };
  const formik = useFormik({
    initialValues: {
      video_title: "",
    },
    validationSchema: Yup.object().shape({
      video_title: Yup.string().required("Video Title is required"),
    }),
    onSubmit: handleSubmit,
  });
  useLayoutEffect(() => {
    document.title = "Requests | Nova";
  }, []);

  return (
    <div className="bg-primaryBlack-dark font-beVietnamPro">
      {isLoading ? (
        <div className="py-32">
          <Loading />
        </div>
      ) : error ? (
        <div className="py-32">
          <Error error={error} />
        </div>
      ) : (
        <div className="">
          <div className=" pt-28 md:pt-32 h-[12rem] md:h-[15rem] bg-gradient-to-b from-accentBlue-dark to-accentPurple-dark">
            <h1 className="font-DM text-white w-11/12 mx-auto text-lg md:text-3xl">
              Request
            </h1>
          </div>
          {pendingRequest.length === 0 ? (
            <div className="font-DM w-11/12 flex flex-col gap-2 py-20 items-center text-xl md:text-2xl justify-center  tracking-wide mx-auto">
              <h1 className="text-white/90">
                You don't have video requests yet!!!
              </h1>
              <FaVideoSlash className="text-4xl text-white/90" />
            </div>
          ) : (
            <div className="w-11/12 mx-auto mt-10 pb-32">
              <h1 className="font-DM flex text-lg md:text-xl tracking-wide text-white pb-4">
                List of requests from your fans
              </h1>
              <div className="flex rounded-xl ">
                <div
                  className={`w-full pb-6 rounded-xl md:rounded-r-none md:w-2/5 lg:w-1/3  bg-accentPurple-dark/20  ${
                    selectedCardMob ? "hidden md:block" : ""
                  }`}
                >
                  {pendingRequest.map((item, index) => {
                    return (
                      <RequestCards
                        request={item}
                        profile={avatar}
                        key={index}
                        handleClick={() => handleRequestClick(item, index)}
                        isSelected={selectedCard === index}
                      />
                    );
                  })}
                </div>
                <Toaster />
                <div
                  className={`bg-message-pattern rounded-xl md:rounded-l-none ${
                    !selectedCardMob
                      ? "hidden md:flex w-full justify-center"
                      : "flex w-full"
                  }`}
                >
                  {updating ? (
                    <div className="flex justify-center items-center py-20 mx-auto">
                      <Loading />
                    </div>
                  ) : (
                    <div>
                      <Toaster />
                      {messageDetails ? (
                        <div className="p-4 text-xs lg:text-sm w-full text-white font-light flex flex-col gap-4 items-start md:pl-7 lg:pl-10 md:pt-3 lg:pt-7">
                          <FaArrowLeftLong
                            className="flex md:hidden text-xl cursor-pointer hover:text-accentPurple-medium"
                            onClick={() => {
                              setSelectedCardMob(false);
                            }}
                          />

                          <div className="flex flex-col md:flex-row gap-5 items-start">
                            <img
                              src={avatar}
                              alt=""
                              className="w-32 h-32 rounded-2xl object-cover "
                            />
                            <div className="flex flex-col gap-2 capitalize ">
                              <div className="">
                                <span className="text-accentPurple-medium font-semibold">
                                  Users Name:{" "}
                                </span>
                                {messageDetails.user_first_name}{" "}
                                {messageDetails.user_last_name}
                              </div>
                              <div className="">
                                <span className="text-accentPurple-medium font-semibold">
                                  Request Type:{" "}
                                </span>
                                {messageDetails.request_type}
                              </div>
                              <div className="">
                                <span className="text-accentPurple-medium font-semibold">
                                  Recipient Name:{" "}
                                </span>
                                {messageDetails.recipient_name}
                              </div>

                              <div className="">
                                <span className="text-accentPurple-medium font-semibold">
                                  Request Type:{" "}
                                </span>
                                {messageDetails.request_type}
                              </div>
                              <div className="">
                                <span className="text-accentPurple-medium font-semibold">
                                  Request Details:{" "}
                                </span>
                                {messageDetails.request_details}
                              </div>
                            </div>
                          </div>
                          <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col w-full items-start md:items-center gap-2"
                          >
                            <div className=" flex flex-col md:flex-row w-full items-start md:items-center justify-between gap-4 md:gap-8 lg:gap-16">
                              <div className="w-7/12">
                                <Input
                                  type="text"
                                  label="Video Title"
                                  placeHolder="Video Title"
                                  require={true}
                                  name="video_title"
                                  value={formik.values.video_title}
                                  error={
                                    formik.touched.video_title &&
                                    formik.errors.video_title
                                  }
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                              </div>

                              <div className="">
                                <input
                                  type="file"
                                  id="video"
                                  accept="video/*"
                                  className="block w-full text-xs md:text-sm file:mr-4 file:rounded-md cursor-pointer file:border-none file: file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white file:bg-accentPurple-dark/50 hover:file:bg-accentPurple-dark/80 focus:outline-none disabled:pointer-events-none disabled:opacity-60"
                                />
                              </div>
                            </div>
                            <Button
                              type="submit"
                              tertiary
                              small
                              handleClick={() => {
                                alert("china");
                              }}
                            >
                              Submit
                            </Button>
                          </form>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-1 px-2 md:gap-3 items-center text-center text-xl md:text-xl lg:text-3xl justify-start md:py-10 lg:py-28 tracking-wide mx-auto">
                          <h1 className="font-DM text-white">
                            You can send video to your fans here
                          </h1>
                          <FaVideo className="text-3xl  md:text-4xl  lg:text-5xl text-white/75" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Request;
