import axios from "axios";
import { useEffect, useState } from "react";

const useFetchCompletedRequest = (token) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completeRequests, setCompleteRequests] = useState([]);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/completed-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.completedRequests;
        setCompleteRequests(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
    fetchProfile();
  }, [token]);
  return { completeRequests, isLoading, error };
};

export default useFetchCompletedRequest;
