import React, { useEffect, useState } from "react";
import Image from "../../assets/images/profile.svg";
const RequestCards = ({ request, handleClick, isSelected }) => {
  const [avatar, setAvatar] = useState(null);
  const {
    recipient_name,
    request_details,
    user_first_name,
    user_last_name,
    user_profile_image,
  } = request;

  const userName = `${user_first_name} ${user_last_name}`;

  useEffect(() => {
    const imageUrl = ` ${
      user_profile_image === null
        ? Image
        : user_profile_image?.replace("/home/novaet/", "https://")
    }`;
    setAvatar(imageUrl);
  }, [user_profile_image]);

  return (
    <div
      onClick={handleClick}
      className={`text-white border-b-[.5px] border-white/20 flex gap-2 items-center p-3 cursor-pointer transition-all duration-300  ${
        isSelected
          ? "bg-accentPurple-dark/70 rounded-lg hover:bg-accentPurple-dark/90"
          : "hover:bg-accentPurple-dark/30 hover:rounded-lg "
      } `}
    >
      <img
        src={avatar}
        alt={user_first_name}
        className="w-14 h-14 rounded-full object-cover "
      />
      <div className="font-light text-white flex flex-col gap-.5 ">
        <h4 className="text-sm">{userName}</h4>
        <h4 className="text-xs md:text-sm text-white/80">
          {recipient_name}: {request_details.slice(0, 30)}
          {request_details.length > 30 && "..."}
        </h4>
      </div>
    </div>
  );
};

export default RequestCards;
