import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Modal = ({ onClose, children, actionBar }) => {
  useEffect(() => {
    document.body.classList.add("overflow");

    return () => {
      document.body.classList.remove("overflow");
    };
  }, []);
  return ReactDOM.createPortal(
    <div>
      <div
        onClick={onClose}
        className="fixed bg-gradient-to-b from-accentPurple-dark/95 to-accentBlue-dark/95 top-0 left-0 right-0 bottom-0 z-40 "
      ></div>
      <div className="fixed bg-primaryBlack-dark px-5 py-3 w-[18rem] sm:w-[30rem] md:w-[40rem] h-[33rem] top-[50%] left-[50%] right-[40%] bottom-[50%] -translate-y-1/2 -translate-x-1/2 z-50 rounded-lg ">
        <div className="flex justify-end">{actionBar}</div>
        <div className="flex justify-center mx-auto ">
          <div className="w-11/12 md:w-10/12 lg:w-9/12">{children}</div>
        </div>
      </div>
    </div>,
    document.querySelector(".modal-container")
  );
};

export default Modal;
