import { useState } from "react";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import { motion } from "framer-motion";
function Accordion({ items }) {
  //scroll animation

  const BottomCardAnimate = {
    offScreen: {
      y: 100,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleClick = (nextIndex) => {
    setExpandedIndex((currentExpandedIndex) => {
      if (currentExpandedIndex === nextIndex) {
        return -1;
      } else {
        return nextIndex;
      }
    });
  };

  const renderedItems = items.map((item, index) => {
    const isExpanded = index === expandedIndex;
    const icon = (
      <span className="text-2xl">
        {isExpanded ? <GoChevronDown /> : <GoChevronRight />}
      </span>
    );

    return (
      <div key={item.id}>
        <div
          className={`text-sm font-beVietnamPro md:text-base ${
            isExpanded
              ? "bg-accentPurple-dark text-white shadow-md shadow-accentPurple-light"
              : " bg-primaryBlack-medium  text-white border-gray-400/40 border-b"
          } flex justify-between p-4 items-center cursor-pointer`}
          onClick={() => handleClick(index)}
        >
          {item.label}
          {icon}
        </div>
        {isExpanded && (
          <div className=" font-beVietnamPro border-gray-400/40 border-b p-5 font-light text-sm md:text-base text-white/90">
            {item.content}
          </div>
        )}
      </div>
    );
  });

  return (
    <motion.div
      variants={BottomCardAnimate}
      className="border-x border-t border-gray-400/40 rounded shadow"
    >
      {renderedItems}
    </motion.div>
  );
}

export default Accordion;
