import React, { useLayoutEffect } from "react";
import Hero from "../components/layout/Hero";
import HowItWorks from "../components/layout/HowItWorks";
import Explanation from "../components/layout/Explanation";
import FAQ from "../components/layout/FAQ";
import { motion } from "framer-motion";

import {
  FaThumbsUp,
  FaHeart,
  FaFire,
  FaBirthdayCake,
  FaEdit,
  FaPlusSquare,
} from "react-icons/fa";

const Home = () => {
  //scroll animation

  const BottomCardAnimate = {
    offScreen: {
      y: 100,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2,
      },
    },
  };
  useLayoutEffect(() => {
    document.title = "Nova";
  }, []);
  return (
    <div className="bg-primaryBlack-dark overflow-hidden">
      <Hero />
      <Explanation />
      <motion.div
        transition={{ staggerChildren: 0.1 }}
        initial={"offScreen"}
        whileInView={"onScreen"}
        viewport={{ once: true, amount: 0.2 }}
        className="w-11/12 lg:w-10/12 pt-16 md:pt-28 mx-auto font-beVietnamPro "
      >
        <div className="flex flex-col gap-4 md:gap-8 ">
          <div className="flex  items-start md:items-center flex-col gap-1 md:gap-2">
            <p className="text-sm text-center uppercase md:text-lg text-accentBlue-dark tracking-wide">
              Nova Requests
            </p>
            <h2
              className="font-DM text-white text-center text-base md:text-lg lg:text-xl
            xl:text-3xl"
            >
              Requests you'll get
            </h2>
          </div>
          <motion.div
            variants={BottomCardAnimate}
            className="grid md:mx-auto justify-center items-start md:items-center  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-y-7 md:gap-x-20 lg:gap-10"
          >
            <div className="flex gap-2 items-center text-white/90">
              <FaThumbsUp className="text-lg" />
              <span className="  text-sm md:text-base">
                Motivation & Inspiration
              </span>
            </div>
            <div className="flex gap-2 items-center text-white/90">
              <FaFire className="text-lg" />
              <span className="text-sm md:text-base">
                Personalized Celebrations
              </span>
            </div>
            <div className="flex gap-2 items-center text-white/90">
              <FaHeart className="text-lg" />
              <span className=" font-light text-sm md:text-base">
                Warm Wishes & Encouragement
              </span>
            </div>
            <div className="flex gap-2 items-center text-white/90">
              <FaBirthdayCake className="text-lg" />
              <span className=" font-light text-sm md:text-base">
                Well Wishes & Congratulations
              </span>
            </div>
            <div className="flex gap-2 items-center text-white/90">
              <FaEdit className="text-lg" />
              <span className=" font-light text-sm md:text-base">
                Life Advice & Tips
              </span>
            </div>
            <div className="flex gap-2 items-center text-white/90">
              <FaPlusSquare className="text-lg" />
              <span className=" font-light text-sm md:text-base">
                Special Song Dedications
              </span>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <HowItWorks />

      <FAQ />
    </div>
  );
};

export default Home;
