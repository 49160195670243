import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import Button from "../common/Button";
import NavDropDown from "../common/NavDropDown";

const Navigation = () => {
  const location = useLocation();

  const [active, setActive] = useState(false);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const isActive = () => {
      window.scrollY > 0 ? setActive(true) : setActive(false);
      // scroll event handling logic
    };

    window.addEventListener("scroll", isActive);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);
  // takes it to the top when we switch pages

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      className={`w-full h-[4.2rem] z-40 py-5 flex justify-center items-center
        ${
          active
            ? "bg-primaryBlack-medium  z-40 shadow-xl fixed top-0 left-0  "
            : "bg-primaryBlack-dark fixed top-0 left-0  "
        }`}
    >
      <div className="w-11/12 max-w-[1440px] mx-auto flex justify-between items-center">
        <NavLink
          to={`${token ? "/dashboard" : "/"}`}
          className="lg:w-1/7 cursor-pointer items-start flex"
        >
          <div className="flex items-star">
            <img className=" w-18 md:w-24 py-1" src={Logo} alt="" />
          </div>
        </NavLink>

        <div className="flex items-center gap-7 font-montserrat justify-end">
          {token && token !== undefined ? (
            <NavDropDown
              label="Categories"
              data={[
                { name: "dashboard" },
                { name: "profile" },
                { name: "request" },
                { name: "history" },
                { name: "logout" },
              ]}
            />
          ) : (
            <div className="flex  items-center gap-5 md:gap-7 lg:gap-10">
              <Button tertiary small>
                <Link to="/enroll">Enroll</Link>
              </Button>
              <NavLink
                to="/login"
                className="font-beVietnamPro text-sm md:text-base lg:text-lg font-semibold relative cursor-pointer z-10 transition-all bg-[-100%] duration-500 bg-[length:200%_100%] text-transparent bg-text-gradient bg-clip-text hover:bg-[0%]"
              >
                Login
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Navigation;
