import React, { useState } from "react";
import Input from "./Input";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "./Button";
import axios from "axios";
import toast from "react-hot-toast";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
const ChangePassword = ({ showModal }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/talent/change-password`,
        values
      );
      const message = response.data.message;
      toast.success(message);
      navigate("/profile");
      setLoading(false);
      showModal(false);
    } catch (error) {
      const message = error.message;
      setLoading(false);
      toast.error(message);
    }

    resetForm();
  };
  // Validation schema using Yup

  const Schema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    currentPassword: Yup.string().required("Password is required"),
    newPassword: Yup.string()
      .required("Confirm password is required")
      .min(8, "Your password must contain at least 8 characters."),
  });
  // Formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      email: "",
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });
  return (
    <div className="flex flex-col gap-5  text-white w-full">
      {/* <Toaster /> */}
      <div className="text-lg md:text-xl lg:text-2xl font-semibold min-w-min mx-auto flex  flex-col justify-center items-center  ">
        Change Password
      </div>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Input
              type="email"
              label="Email"
              placeHolder="Email"
              require={true}
              name="email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              type="password"
              label="Current Password"
              placeHolder="Current Password"
              require={true}
              name="currentPassword"
              value={formik.values.currentPassword}
              error={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Input
              type="password"
              label="New Password"
              placeHolder="New Password"
              require={true}
              name="newPassword"
              value={formik.values.newPassword}
              error={formik.touched.newPassword && formik.errors.newPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Button type="submit" tertiary>
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
export default ChangePassword;
