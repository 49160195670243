import React from "react";

const Button = ({
  primary,
  secondary,
  tertiary,
  children,
  type,
  handleClick,
  small,
}) => {
  let buttonClasses =
    "font-semibold rounded-full shadow-md transition-all duration-500 hover:shadow-xl";

  if (primary) {
    buttonClasses +=
      " text-accentBlue-dark  bg-white/95 rounded-full hover:bg-white";
  } else if (secondary) {
    buttonClasses +=
      " border-2 text-white/65  py-2 px-3 md:py-2 md:px-5 lg:py-2 lg:px-6 text-sm md:text-base font-normal border-white/65 hover:border-white hover:text-white";
  } else if (tertiary) {
    buttonClasses += `${
      small
        ? " py-1.5 px-6  font-light md:px-8 capitalize lg:py-1.5 lg:px-9 text-sm md:text-base lg:text-lg "
        : " py-2.5 px-10  uppercase  md:px-10 lg:py-4 lg:px-16 text-base lg:text-xl "
    }bg-accentPurple-dark/85  text-white/85 hover:bg-accentPurple-dark`;
  }

  return (
    <button
      type={type || "button"}
      className={buttonClasses}
      onClick={() => handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
