import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Loading from "../components/common/Loading";
import Error from "../components/common/Error";
import HistoryCards from "../components/layout/HistoryCards";
import ModalParent from "../components/common/ModalParent";
import { Toaster } from "react-hot-toast";
import TotalIncome from "../assets/images/money-receive-svgrepo-com.svg";
import CurrentIncome from "../assets/images/money-stack-svgrepo-com.svg";
import Withdrawal from "../assets/images/money-withdrawal-svgrepo-com.svg";
const Dashboard = () => {
  const token = sessionStorage.getItem("token");

  const [completeRequests, setCompleteRequests] = useState([]);
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSummary = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/summary`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      setBalance(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchSummary();
  }, []);
  const fetchCompletedRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/recent-completed-requests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.recentCompletedRequests;

      setCompleteRequests(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchCompletedRequests();
  }, []);
  useLayoutEffect(() => {
    document.title = "Dashboard | Nova";
  }, []);

  return (
    <div className="bg-primaryBlack-dark font-beVietnamPro">
      <Toaster />
      {isLoading ? (
        <div className="py-32">
          <Loading />
        </div>
      ) : error ? (
        <div className="py-32">
          <Error error={error} />
        </div>
      ) : (
        <div>
          <div className=" pt-28 h-[12rem] md:h-[20rem] bg-gradient-to-b from-accentBlue-dark to-accentPurple-dark">
            <h1 className="font-DM text-white w-11/12 mx-auto text-lg md:text-3xl">
              Dashboard
            </h1>
          </div>
          <div className="md:-translate-y-1/2 py-7 md:py-0 w-11/12 relative mx-auto text-white flex flex-col md:flex-row gap-5 items-start">
            <div className="font-DM w-full md:w-1/3 p-4 text-base lg:text-lg flex flex-col  gap-3 md:gap-6  bg-primaryBlack-medium rounded-lg">
              <div className="flex justify-between items-end ">
                <h2 className="md:text-lg font-DM text-white/90">
                  Total Income
                </h2>
                <div className=" bg-accentPurple-dark/80 p-1 rounded-md">
                  <img
                    src={TotalIncome}
                    alt="current income icon"
                    className="w-6 md:w-7 lg:w-9 "
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <h2 className=" text-xl  md:text-2xl lg:text-3xl  text-accentPurple-medium font-bold">
                  {balance.totalIncome}
                </h2>
                <h2 className="text-sm md:text-base lg:text-lg text-white/90">
                  Birr
                </h2>
              </div>
            </div>
            <div className="font-DM w-full md:w-1/3 p-4 text-base lg:text-lg flex flex-col  gap-3 md:gap-6  bg-primaryBlack-medium rounded-lg">
              <div className="flex justify-between items-end ">
                <h2 className="md:text-lg  text-white/90"> Current Balance</h2>
                <div className=" bg-accentPurple-dark/80 p-1 rounded-md">
                  <img
                    src={CurrentIncome}
                    alt="current income icon"
                    className="w-6 md:w-7 lg:w-9 "
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <h2 className="text-xl md:text-2xl lg:text-3xl  text-accentPurple-medium font-bold">
                  {balance.totalCurrentBalance}
                </h2>
                <h2 className="text-sm md:text-base lg:text-lg text-white/90">
                  Birr
                </h2>
              </div>
            </div>
            <div className="font-DM w-full md:w-1/3 p-4 text-base lg:text-lg flex flex-col  gap-3 md:gap-6  bg-primaryBlack-medium rounded-lg">
              <div className=" flex justify-between items-end ">
                <h2 className="md:text-lg  text-white/90">
                  {" "}
                  Withdrawed Amount
                </h2>
                <div className=" bg-accentPurple-dark/80 p-1 rounded-md">
                  <img
                    src={Withdrawal}
                    alt="current income icon"
                    className="w-6 md:w-7 lg:w-9 "
                  />
                </div>
              </div>
              <div className="font-DM  flex flex-col items-start">
                <h2 className="text-xl md:text-2xl lg:text-3xl  text-accentPurple-medium font-bold">
                  {balance.totalWithdrawedAmount}
                </h2>
                <h2 className="text-sm md:text-base lg:text-lg text-white/90">
                  Birr
                </h2>
              </div>
            </div>
          </div>
          <div className="md:-translate-y-1/2 pb-10 pt-2 md:pt-10 w-11/12 mx-auto flex justify-end">
            <div className="bg-accentPurple-dark/80  hover:bg-accentPurple-dark  transition-all duration-300 text-white font-semibold py-3 px-8 rounded-full">
              <ModalParent withdraw />
            </div>
          </div>
          {completeRequests.length === 0 ? null : (
            <div className="w-11/12 flex flex-col gap-2 mx-auto pb-28">
              <h1 className="font-DM text-xl md:text-3xl mb-2 md:mb-4 tracking-wide text-white">
                Your Completed Request
              </h1>
              <div className="overflow-x-auto table-scrollbar">
                <table className="table-auto w-[760px] md:w-full bg-gradient-to-br from-[#1a0e30ab] to-[#38216479] ">
                  <thead>
                    <tr className="bg-accentPurple-dark/45 text-sm md:text-base text-center text-white">
                      <td className="p-3 w-1/6"> Recipient Name</td>
                      <td className="p-3 w-1/6">RecipientPic</td>
                      <td className="p-3 w-1/6"> Recipient Type</td>
                      <td className="p-3 w-1/6"> Request Type</td>
                      <td className="p-3 w-1/6"> Rate </td>
                      <td className="p-3 w-1/6"> Payment Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {completeRequests.map((item, index) => (
                      <HistoryCards request={item} key={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
