import * as Yup from "yup";
import DropDown from "../components/common/DropDown";
import React, { useLayoutEffect, useState } from "react";
import Button from "../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/common/Input";
import { useFormik } from "formik";
import { talent } from "../data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../components/common/Loading";

const Talent = () => {
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register-talent`,
        values
      );
      const message = response.data.message;
      toast.success(message);

      setTimeout(() => {
        navigate("/verify", {
          state: { email: values.email, isUserTalent: false },
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      const message = error.message;
      toast.error(message);
      resetForm();
    }
  };

  const Schema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      )
      .required("Enter a value for this field."),
    skill: Yup.string().required("Skill is required"),
    password: Yup.string()
      .min(8, "Your password must contain at least 8 characters.")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      skill: skills,
      password: "",
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });
  useLayoutEffect(() => {
    document.title = "Apply to join as a talent | Nova";
  }, []);
  return (
    <div className="py-36 bg-primaryBlack-dark font-beVietnamPro">
      <div className="w-11/12 mx-auto flex flex-col items-center gap-2 md:gap-3">
        <h1 className="font-DM text-center mx-auto justify-center capitalize text-xl sm:text-2xl md:text-3xl   lg:text-5xl tracking-wide text-white">
          Get paid to connect with
          <span className="bg-gradient-to-r from-accentBlue-dark  font-semibold via-accentPurple-medium to-accentPurple-dark inline-block text-transparent bg-clip-text">
            &nbsp;fans
          </span>
        </h1>
        <p className="text-center text-light  text-white/75 text-sm md:text-base lg:text-lg">
          Record personalized videos from your phone and start earning.
        </p>
      </div>
      <div className="py-8 w-11/12 mx-auto grid gird-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 ">
        {talent.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-primaryBlack-medium/85 rounded-xl  p-6 md:p-7 transition-all duration-100 hover:bg-primaryBlack-medium"
            >
              <div className="flex flex-col gap-1.5 md:gap-3">
                <div className="flex flex-col gap-1 md:gap-2">
                  <div className="text-white/90 text-2xl md:text-3xl">
                    {item.icon}
                  </div>
                  <h1 className="font-DM text-base md:text-lg lg:text-xl text-white">
                    {item.title}
                  </h1>
                </div>
                <p className="text-white/75 font-beVietnamPro  font-light text-sm">
                  {item.desc}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-10/12 sm:w-8/12 md:w-1/2 lg:w-1/3 mx-auto">
        <div className="py-3">
          <h1 className="font-DM pb-5 md:pb-7 mx-auto flex justify-center text-2xl md:text-3xl lg:text-4xl tracking-wide text-white">
            Join as a talent
          </h1>
          <Toaster />
          {loading ? (
            <Loading />
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-6"
            >
              <div className="flex flex-col gap-1 md:gap-3">
                <Input
                  type="text"
                  label="First Name"
                  placeHolder="First Name"
                  require={true}
                  name="firstName"
                  value={formik.values.firstName}
                  error={formik.touched.firstName && formik.errors.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="text"
                  label="Last Name"
                  placeHolder="Last Name"
                  require={true}
                  name="lastName"
                  value={formik.values.lastName}
                  error={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="email"
                  label="Email"
                  placeHolder="example@gmail.com"
                  require={true}
                  name="email"
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                <Input
                  type="password"
                  label="Password"
                  placeHolder="Password"
                  require={true}
                  name="password"
                  value={formik.values.password}
                  error={formik.touched.password && formik.errors.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <DropDown
                  header="Skills"
                  label="Skills"
                  data={[
                    { name: "actors" },
                    { name: "musicians" },
                    { name: "tv-hosts" },
                    { name: "comedians" },
                    { name: "directors" },
                    { name: "influencers" },
                  ]}
                  error={formik.touched.skill && formik.errors.skill}
                  onChange={(selectedSkill) => {
                    setSkills(selectedSkill);
                    formik.setFieldValue("skill", selectedSkill); // Update formik value
                  }}
                  onBlur={formik.handleBlur}
                />

                <div className="font-beVietnamPro text-center justify-center text-sm text-white/50 font-light">
                  If you already have an account, please
                  <Link
                    to="/login"
                    className="uppercase font-normal  tracking-wider transition-all duration-500 text-accentBlue-dark hover:text-accentBlue-medium"
                  >
                    {" "}
                    login
                  </Link>
                </div>
              </div>

              <Button type="submit" tertiary handleClick={handleSubmit}>
                Continue
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Talent;
