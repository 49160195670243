import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";

const ForgotPassword = ({ onLoginClick }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      // Getting response from the API
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-talent-password`,
        values
      );
      const message = response.data.message;
      toast.success(message);
      setTimeout(() => {
        navigate("/verify-and-reset", {
          state: { email: values.email },
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      const message = error.message;
      toast.error(message);
    }
    resetForm();
  };

  const Schema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      )
      .required("Enter a value for this field."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Schema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Toaster />
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col  gap-1 md:gap-3">
            <Input
              type="email"
              label="Email"
              placeHolder="example@gmail.com"
              require={true}
              name="email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />

            <Button type="submit" tertiary handleClick={handleSubmit}>
              Submit
            </Button>
          </div>

          <div
            className="flex gap-1 items-center font-roboto font-light transition-all duration-500 justify-center cursor-pointer  text-white/70 hover:text-white  text-center "
            onClick={onLoginClick}
          >
            <IoIosArrowBack className="text-xl" />
            <span>Back to Login</span>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
