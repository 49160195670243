import React, { useState } from "react";
import Input from "./Input";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "./Button";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
const Withdraw = ({ showModal }) => {
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_TALENT_PROFILE_URL}/request-withdrawal`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const message = response.data.message;
      toast.success(message);
      navigate("/dashboard");
      setLoading(false);
      showModal(false);
    } catch (error) {
      const message = error.message;
      setLoading(false);
      toast.error(message);
    }

    resetForm();
  };

  // Formik hook for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: handleSubmit,
  });
  return (
    <div className="flex flex-col pt-20 gap-5  text-white w-full">
      <Toaster />
      <div className="text-lg md:text-xl lg:text-2xl font-semibold min-w-min mx-auto flex  flex-col justify-center items-center  ">
        Withdraw
      </div>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Input
              type="text"
              label="Amount"
              placeHolder="Amount"
              require={true}
              name="amount"
              value={formik.values.amount}
              error={formik.touched.amount && formik.errors.amount}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />

            <Button type="submit" tertiary>
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};
export default Withdraw;
